@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Josefin+Sans:ital,wght@0,300;0,400;1,300;1,400&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header{
  background-image: url("./assest/image/puzzle.png");
  background-size: 200px;
  background-repeat: repeat;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a.active {
  color: #1F2358;
  background-color: #E0E2FF;
  padding: 0px 10px !important;
}

.activelink {
  background-color: #F3F4FF;
  animation: example 1s linear 0s  alternate;
  padding: 10px 0px 20px 0;
}
.animate {
  transition: all 0.7s ease;
  /* animation: example 5s linear 2s 5s alternate; */
}
.ant-dropdown-menu-item.ant-dropdown-menu-item.active-only-child:hover {
  background-color: none !important;
}
@keyframes example {
  0%   {background-color: #E0E2FF;}
  /* 25%  {background-color: yellow;}
  50%  {background-color: blue;} */
  100% {background-color: #F3F4FF;}
}
.hoverEffect {
  transition: all 0.7s ease;
}

.hoverEffect:hover {
  scale: 1.04;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #E0E2FF;
  border-bottom: none !important;
  /* padding: 0px 10px !important; */
}
.ant-collapse-item {
  margin-bottom: 5px;
}
.ant-collapse-header {
  background-color: #F1F1FF;
  padding: 5px 10px;
}
.ant-collapse-content.ant-collapse-content-active {
  background-color: #fff !important;
  padding: 5px 10px;
}
/* .ant-dropdown-menu  {
  width: 200px;
  padding-top: 20px;
}

.second_item .ant-dropdown-menu-title-content {
  padding: 10px 0;
} */
 .ant-dropdown-menu-title-content {
  display: inline-block;
 }

 :where(.css-dev-only-do-not-override-4lted3).ant-form legend{
  border: none !important;
}
:where(.css-dev-only-do-not-override-1em319h).ant-collapse-borderless >.ant-collapse-item>.ant-collapse-content{
  background-color: white !important;
  padding: 12px 8px !important;
}